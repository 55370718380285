import { Link } from "gatsby"
import React from "react"
import "../css/bootstrap.min.css"
import "./header.css"
import gallery1 from "../images/portfolio/spreets.png"
import gallery2 from "../images/portfolio/launchpad.png"
import gallery3 from "../images/portfolio/air.png"
import gallery4 from "../images/portfolio/binu.png"
import gallery5 from "../images/portfolio/pillo.png"
import gallery6 from "../images/portfolio/pocket.png"
import gallery7 from "../images/portfolio/logistic.png"
import gallery8 from "../images/portfolio/YourMoo.png"

import gallery9 from "../images/portfolio/passpod.png"
import gallery10 from "../images/portfolio/bc.png"
import gallery11 from "../images/portfolio/voip.png"
import gallery12 from "../images/portfolio/kc.png"
import Loader from 'react-loader-spinner'



const Gallery = (index, data) => {
    const [showResults, setShowResults] = React.useState(false)
    const onClick = () => {
        setShowResults(true)
    }
    return(
        <section className="skew-bg gallery-wrapper" id="gallery">
            {showResults ?
                <div className="loader-main">
                <Loader type="Bars" color="#00BFFF" height={100} width={100} /></div> : null
            }
            <div className="containers">
                <div className="rows">
                    <div className="col-md-12 col-sm-12">
                        <h1 className="main-title head-h1 mb-4">Our <span className="h1-bold">Works</span></h1>
                        <span className="small-border"></span>
                    </div>
                </div>
                <div className="rows gallery">
                    <Link className="col-lg-3 col-md-3 col-xs-6 thumb target-link"  target="_blank" to="/projectDetails"
                          state={{choice: 'yahooSpreets'}} onClick={onClick}>
                        <div>
                <span className="overlay">
                    <span className="icon">
                        <i className="fa fa-align-justify fa-external-link icon-info"></i>
                    </span>
                    <span className="pf_text">
                        <span className="project-name">Yahoo Spreets.</span>
                    </span>
                        </span>
                            <img className="img-fluid img-thumbnail" src={gallery1} alt="spreets"/>
                        </div>
                    </Link>
                    <Link className="col-lg-3 col-md-3 col-xs-6 thumb target-link" onClick={onClick} target="_blank"
                          to={"/projectDetails/#launchpad"} state={{choice: 'launchpad'}}>
                        <div>
                <span className="overlay">
                    <span className="icon">
                        <i className="fa fa-align-justify fa-external-link icon-info"></i>
                    </span>
                    <span className="pf_text">
                        <span className="project-name">Launchpad</span>
                    </span>
                </span>
                            <img className="img-fluid img-thumbnail" src={gallery2} alt="launchpad"/>
                        </div>
                    </Link>
                    <Link className="col-lg-3 col-md-3 col-xs-6 thumb target-link" onClick={onClick} target="_blank"
                          to={`/projectDetails/#civilAviationAuthority`} state={{choice: 'civilAviationAuthority'}}>
                        <div>
                <span className="overlay">
                    <span className="icon">
                        <i className="fa fa-align-justify fa-external-link icon-info"></i>
                    </span>
                    <span className="pf_text">
                        <span className="project-name">UAE Civil Aviation Authority</span>
                    </span>
                </span>
                            <img className="img-fluid img-thumbnail" src={gallery3} alt="air"/>
                        </div>
                    </Link>
                    <Link className="col-lg-3 col-md-3 col-xs-6 thumb target-link" onClick={onClick} target="_blank"
                          to="/projectDetails/#binu" state={{choice: 'binu'}}>
                        <div>
                <span className="overlay">
                    <span className="icon">
                        <i className="fa fa-align-justify fa-external-link icon-info"></i>
                    </span>
                    <span className="pf_text">
                        <span className="project-name">biNu</span>
                    </span>
                </span>
                            <img className="img-fluid img-thumbnail" src={gallery4} alt="binu"/>
                        </div>
                    </Link>

                    <Link className="col-lg-3 col-md-3 col-xs-6 thumb target-link" onClick={onClick} target="_blank"
                          to="/projectDetails/#pillo" state={{choice: 'pillo'}}>
                        <div>
                <span className="overlay">
                    <span className="icon">
                        <i className="fa fa-align-justify fa-external-link icon-info"></i>
                    </span>
                    <span className="pf_text">
                        <span className="project-name">Pillo</span>
                    </span>
                </span>
                            <img className="img-fluid img-thumbnail" src={gallery5} alt="Random"/>
                        </div>
                    </Link>
                    <Link className="col-lg-3 col-md-3 col-xs-6 thumb target-link" onClick={onClick} target="_blank"
                          to="/projectDetails/#pocketSports" state={{choice: 'pocketSports'}}>
                        <div>
                <span className="overlay">
                    <span className="icon">
                        <i className="fa fa-align-justify fa-external-link icon-info"></i>
                    </span>
                    <span className="pf_text">
                        <span className="project-name">Pocket Sports</span>
                    </span>
                </span>
                            <img className="img-fluid img-thumbnail" src={gallery6} alt="Random"/>
                        </div>
                    </Link>

                    <Link className="col-lg-3 col-md-3 col-xs-6 thumb target-link" onClick={onClick} target="_blank"
                          to="/projectDetails/#logisticPlatform" state={{choice: 'logisticPlatform'}}>
                        <div>
                <span className="overlay">
                    <span className="icon">
                        <i className="fa fa-align-justify fa-external-link icon-info"></i>
                    </span>
                    <span className="pf_text">
                        <span className="project-name">A video logistic platform</span>
                    </span>
                </span>
                            <img className="img-fluid img-thumbnail" src={gallery7} alt="Random"/>
                        </div>
                    </Link>

                    <Link className="col-lg-3 col-md-3 col-xs-6 thumb target-link" onClick={onClick} target="_blank"
                          to="/projectDetails/#yourMoo" state={{choice: 'yourMoo'}}>
                        <div>
                <span className="overlay">
                    <span className="icon">
                        <i className="fa fa-align-justify fa-external-link icon-info"></i>
                    </span>
                    <span className="pf_text">
                        <span className="project-name">YourMoo</span>
                    </span>
                </span>
                            <img className="img-fluid img-thumbnail" src={gallery8} alt="Random"/>
                        </div>
                    </Link>

                    <Link className="col-lg-3 col-md-3 col-xs-6 thumb target-link" onClick={onClick} target="_blank"
                          to="/projectDetails/#passpod" state={{choice: 'passpod'}}>
                        <div>
                <span className="overlay">
                    <span className="icon">
                        <i className="fa fa-align-justify fa-external-link icon-info"></i>
                    </span>
                    <span className="pf_text">
                        <span className="project-name">Passpod</span>
                    </span>
                </span>
                            <img className="img-fluid img-thumbnail" src={gallery9} alt="Passpod"/>
                        </div>
                    </Link>

                    <Link className="col-lg-3 col-md-3 col-xs-6 thumb target-link" onClick={onClick} target="_blank"
                          to="/projectDetails/#betterClinics" state={{choice: 'betterClinics'}}>
                        <div>
                <span className="overlay">
                    <span className="icon">
                        <i className="fa fa-align-justify fa-external-link icon-info"></i>
                    </span>
                    <span className="pf_text">
                        <span className="project-name">Better Clinics</span>
                    </span>
                </span>
                            <img className="img-fluid img-thumbnail" src={gallery10} alt="BetterClinics"/>
                        </div>
                    </Link>

                    <Link className="col-lg-3 col-md-3 col-xs-6 thumb target-link" onClick={onClick} target="_blank"
                          to="/projectDetails/#voip" state={{choice: 'voip'}}>
                        <div>
                <span className="overlay">
                    <span className="icon">
                        <i className="fa fa-align-justify fa-external-link icon-info"></i>
                    </span>
                    <span className="pf_text">
                        <span className="project-name">Dr Voip</span>
                    </span>
                </span>
                            <img className="img-fluid img-thumbnail" src={gallery11} alt="DrVoip"/>
                        </div>
                    </Link>

                    <Link className="col-lg-3 col-md-3 col-xs-6 thumb target-link" onClick={onClick} target="_blank"
                          to="/projectDetails/#kingContent" state={{choice: 'kingContent'}}>
                        <div>
                <span className="overlay">
                    <span className="icon">
                        <i className="fa fa-align-justify fa-external-link icon-info"></i>
                    </span>
                    <span className="pf_text">
                        <span className="project-name">King Content</span>
                    </span>
                </span>
                            <img className="img-fluid img-thumbnail" src={gallery12} alt="KingContent"/>
                        </div>
                    </Link>
                </div>
            </div>
            <div id="view-all-projects" className="call-to-action bg-color dark text-center">
                <Link to="/projectDetails" target="_blank" className="view-all">View More</Link>
            </div>
        </section>

    )
}

export default Gallery