/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */
import React from "react"
import "../css/style.css"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import logoOne from "../images/logo-1.png"
import logoSecond from "../images/logo-2.png"
import logoThird from "../images/logo-3.png"
import logoFour from "../images/logo-4.png"
import logoFive from "../images/logo-5.png"

const Partner = (index, data) => {
      const settings = {
  dots: false,
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      speed: 1500,
        autoplaySpeed: 1500,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1
            }
          }
        ],
      
   appendDots: dots => (
     <div
       style={{
               borderRadius: "10px",
               padding: "10px",
       }}
      >
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </div>
    ),
    customPaging: i => (
      <div
        style={{
          width: "85px",
         height: "85px",
        }}
      >
      </div>
    ),
      }
  

  return (
    <section className="skew-bg partner-wrapper" id="partner">
      <div className="container">
        <div className="rows">
          <div className="col-md-12 col-sm-12">
            <h1 className="main-title head-h1 mb-4">Our <span className="h1-bold">Partners</span></h1>
            <span className="small-border"></span>
          </div>
        </div>
              <div className="rowa">
                  <Slider {...settings}>
                      <div className="glide__slide">
                        <img src={logoOne} alt="demo" />
                      </div>
                       <div className="glide__slide">
                        <img src={logoSecond} alt="demo" />
                      </div>
                       <div className="glide__slide">
                        <img src={logoThird} alt="demo" />
                    </div>
                     <div className="glide__slide">
                        <img src={logoFour} alt="demo" />
                    </div>
                     <div className="glide__slide">
                        <img src={logoFive} alt="demo" />
                    </div>
                </Slider>
              </div>
      </div>
    </section>
  )
}

export default Partner
