
/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */
import React from "react"
import "../css/style.css"
import { Carousel } from "react-bootstrap"
import testimonialsOne from "../images/testimonials/jeremy-samuel.jpg"
import testimonialsTwo from "../images//testimonials/charles-jacobson.jpg"
import testimonialsThree from "../images/testimonials/cameron-reilly.jpg"
import testimonialsFour from "../images/testimonials/christiaan-erik-rijnders.jpg"

const Testimonials = () => {
  return (
    <section className="skew-bg testimonials-wrapper" id="testimonials-wrapper">
      <div className="container-lg container-md">
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <h1 className="main-title head-h1">Testimonials</h1>
          </div>
        </div>
        <div className="row">
          <Carousel>
            <Carousel.Item>
              <div className="quote-icon"><i className="fa fa-quote-right"></i></div>
              <div>
                <Carousel.Caption>
                  <p>
                  "We used XMinds when we were in a whole world of pain… other developers had
                  let us down badly.XMinds came in , did a qa of the existing code, created a handover and completion plan and then set about getting the job done properly.After our first experience, we were pretty burned.By the time XMinds got done, we were very satisfied.They delivered a great result, were fantastic to work with and provided code that could be easily understood and kept updated.I would definitely recommend them
                  for any complex software development project."
                  </p>
                </Carousel.Caption>
                <div className="creator-block">
                  <h3>Jeremy Samuel</h3>
                </div>
              </div>
              <div className="indicator-pic">
                 <img src={testimonialsOne} alt="Jeremy Samuel" />
              </div>
            </Carousel.Item>
             <Carousel.Item>
              <div className="quote-icon"><i className="fa fa-quote-right"></i></div>
              <div>
                <Carousel.Caption>
                  <p>
                    “We’ve used Xminds at King Content for the past 4 years. During this time they’ve provided skillful developers who communicate well and are a pleasure to deal with. Xminds are also very flexible and ready help no matter how short the time-frame is. They’ve made offshore development easy for King Content and I’ll definitely use them again.”
                  </p>
                </Carousel.Caption>
                <div className="creator-block">
                  <h3>Charles Jacobson</h3>
                  <span  className="creator-des">CTO - Strategy and Content, Isentia</span>
              </div>
              </div>
                            <div className="indicator-pic">
                 <img src={testimonialsTwo} alt="Charles Jacobson" />
              </div>
            </Carousel.Item>
             <Carousel.Item>
              <div className="quote-icon"><i className="fa fa-quote-right"></i></div>
              <div>
                <Carousel.Caption>
                  <p>
                    “I have had the pleasure of working with XMinds for the last 18 months. In that time, I have found the team to be extremely reliable, competent and eager to assist. Team at Xminds have assisted my company through many problems and always developed innovative and creative solutions. I highly recommend XMinds!”
                  </p>
                </Carousel.Caption>
                <div className="creator-block">
                  <h3>Cameron Reilly</h3>
                  <span className="creator-des">CEO, Founder at The Podcast Network</span>
              </div>
              </div>
                            <div className="indicator-pic">
                 <img src={testimonialsThree} alt="Cameron Reilly" />
              </div>
            </Carousel.Item>
             <Carousel.Item>
              <div className="quote-icon"><i className="fa fa-quote-right"></i></div>
              <div>
                <Carousel.Caption>
                  <p>
                    “Xminds did a thorough, creative and professional job on the interface of our Augmented Reality Eyes application for Android. The remote integration with the work of our engineers in Italy was seamless and Xminds delivered exactly on time. We look forward to working with Xminds again.”
                  </p>
                </Carousel.Caption>
                <div className="creator-block">
                  <h3>Christiaan Erik Rijnders</h3>
                  <span className="creator-des">CEO, Founder at The Podcast Network</span>
              </div>
              </div>
                <div className="indicator-pic">
                 <img src={testimonialsFour} alt="Christiaan Erik Rijnders" />
              </div>
            </Carousel.Item>
          </Carousel>
        </div>
      </div>
    </section>
  )
    }
    
    export default Testimonials
