import React , { useState } from "react"

import Layout from "../components/layout"
import Expertise from '../components/expertise'
import Blog from '../components/blog'
import Contact from '../components/contact'
import Testimonials from '../components/testimonials'
import SEO from "../components/seo"
import 'font-awesome/css/font-awesome.min.css';
import Gallery from "../components/gallery";
import Clutch from "../components/clutch";
import "animate.css";
import Partner from "../components/partner"
import CookieConsent from 'react-cookie-consent';
import { Link } from "gatsby"



const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Xminds Infotech Pvt Ltd" />
      <Expertise />
      <Gallery />
      <Blog />
      <Testimonials />
      <Clutch />
      <Partner />
      <Contact />
      <CookieConsent
        enableDeclineButton
        flipButtons
        buttonStyle={{
          fontWeight: "normal",
          borderRadius: "4px",
          fontSize: "14px",
        }}
        style={{
          background: "#2B373B",
          color: "#565662",
          lineHeight: "20px",
          fontSize: "14px",
          fontWeight: "normal",
          alignItem: "center",
        }}
        buttonClasses="btn btn-primary"
        containerClasses="cookie-bar"
        contentClasses="text-lead"
        location="bottom"
        buttonText="Accept"
        declineButtonText="Decline"
        cookieName="gatsby-gdpr-google-analytics"
      >
        We use cookies on our website to give you the most relevant experience
        by remembering your preferences and repeat visits. By clicking “Accept”,
        you consent to the use of ALL the cookies. However you may visit Cookie
        Settings to provide a controlled consent
        <Link
          className="cookie-link"
          target="_blank"
          to="/privacyPolicy"
          style={{
            paddingLeft: "10px",
            cursor: "pointer",
            fontSize: "13px",
            textDecoration: "underline",
          }}
        >
          Privacy policy
        </Link>
      </CookieConsent>
    </Layout>
  )}

export default IndexPage
