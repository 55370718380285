/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */
import React from "react"
import { Link } from "gatsby"
import "../css/style.css"
import "slick-carousel/slick/slick.css"
import { Carousel } from "react-bootstrap"
import screenone from "../images/clutch/screen-1.jpg"
import screentwo from "../images/clutch/screen-2.jpg"
import screenthree from "../images/clutch/screen-3.jpg"
import screenfour from "../images/clutch/screen-4.jpg"
import screenfive from "../images/clutch/screen-5.jpg"
import screensix from "../images/clutch/screen-6.jpg"
import screenseven from "../images/clutch/screen-7.jpg"
import screeneight from "../images/clutch/screen-8.jpg"

import clutch from "../images/clutch/clutch-logo.png"

const Clutch = (index, data) => {

  return (
    <section className="skew-bg clutch-wrapper" id="clutch">

      <div className="container-lg container-md  animateup-animated">
        <div className="row">
          <div className="col-md-12 col-lg-7">
            <div className="clutch-row">
              <div className="about-section-three__image">
              <Carousel>
            <Carousel.Item>
              <div className="glide__slide">
                <img src={screenone} alt="demo" />
              </div>
                  </Carousel.Item>  
             <Carousel.Item>
              <div className="glide__slide">
                <img src={screentwo} alt="demo" />
              </div>
             </Carousel.Item>   
             <Carousel.Item>
              <div className="glide__slide">
                <img src={screenthree} alt="demo" />
              </div>
                  </Carousel.Item>   
             <Carousel.Item>
              <div className="glide__slide">
                <img src={screenfour} alt="demo" />
              </div>
             </Carousel.Item>   
             <Carousel.Item>
              <div className="glide__slide">
                <img src={screenfive} alt="demo" />
              </div>
             </Carousel.Item>   
             <Carousel.Item>
              <div className="glide__slide">
                <img src={screensix} alt="demo" />
              </div>
             </Carousel.Item>   
             <Carousel.Item>
              <div className="glide__slide">
                <img src={screenseven} alt="demo" />
              </div>
                  </Carousel.Item>   
             <Carousel.Item>
              <div className="glide__slide">
                <img src={screeneight} alt="demo" />
              </div>
             </Carousel.Item>        
          </Carousel>
            </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-5">
            <div className="about-section-three__content">
              <div className="sec-title-two text-left">
                <div className="clutch-logo">
                  <img src={clutch} alt="demo" />
                  <p>Recognized on Clutch as a Top Web Developer in India</p>
                  </div>
                    <h2>See our reviews on Clutch</h2>
                </div>
                <div className="about-section-three__summery">
                    <p>Xminds has worked with more than 200 businesses (and counting) in the past 14 years and we offer our experience with the best expert talent pool...</p>
              </div>
              <Link className="btn-1 learn-more-btn" target="_blank"  to="https://clutch.co/profile/xminds-infotech">Read More</Link>
            </div>

          </div>
        </div>
      </div>
    </section>
  )
}

export default Clutch
