/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */
import React, { useState } from "react"
import "../css/style.css"

function validateEmail(mail) {
    if (
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
            mail
        )
    ) {
        return true;
    }
    return false;
}
const Contact = () => {
    let [status, setStatus] = useState("");
    let [error, setError] = useState({});
    let submitForm = (ev) => {
        ev.preventDefault();
        const name = document.getElementById("name").value;
        const email = document.getElementById("email").value;
        const subject = document.getElementById("subject").value;
        const message = document.getElementById("message").value;
        if (
            (!name || !name.trim()) &&
            (!email || !email.trim()) &&
            (!subject || !subject.trim()) &&
            (!message || !message.trim())
        ) {
            setError({
                name: "Please enter name",
                email: "Please enter valid email",
                subject: "Please enter subject",
                message: "Please enter your message",
            });
            return;
        }
        if (!name || !name.trim()) {
            setError({
                name: "Please enter name",
            });
            return;
        }
        if (!email || !email.trim() || !validateEmail(email)) {
            setError({
                email: "Please enter valid email",
            });
            return;
        }
        if (!subject || !subject.trim()) {
            setError({
                subject: "Please enter your subject",
            });
            return;
        }
        if (!message || !message.trim()) {
            setError({
                message: "Please enter your message",
            });
            return;
        }
        setError({
            email: null,
            name: null,
            subject: null,
            message: null,
        });
        const form = ev.target;
        const data = new FormData(form);
        const xhr = new XMLHttpRequest();
        xhr.open(form.method, form.action);
        xhr.setRequestHeader("Accept", "application/json");
        xhr.onreadystatechange = () => {
            if (xhr.readyState !== XMLHttpRequest.DONE) return;
            if (xhr.status === 200) {
                form.reset();
                setStatus("SUCCESS");
            } else {
                setStatus("ERROR");
            }
        };
        xhr.send(data);
    };
    return (
        <section className="skew-bg contact" id="contact">
            <div className="container-lg container-md">
                <div className="row">
                    <div className="col-md-12 col-sm-12 mb-4">
                        <h1 className="main-title">Contact <span className="h1-bold">Us</span></h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 col-sm-12 col-lg-7">
                        <div className="row">
                            <div className="col-md-6 col-sm-6">
                                <div className="contact-address">
                                    <div className="address-item">
                                        <div className="address-text">
                                            <h3 className="contact-title">USA Office</h3>
                                            <p>3511 Chancery Ln,  <br />Carpentersville, Illinois,<br />USA 60110</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6">
                                <div className="contact-address">
                                    <div className="address-item">
                                        <div className="address-text">
                                            <h3 className="contact-title">Australia Office</h3>
                                            <p>Xminds Solutions | Australia,  <br />Suite 1203, 23 Hunter Street,<br />Sydney, NSW, 2000</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6">
                                <div className="contact-address">
                                    <div className="address-item">
                                        <div className="address-text">
                                            <h3 className="contact-title">Europe Office</h3>
                                            <p>TACEMA,  <br />Via Garibaldi 9/C,<br />24122 Bergamo – Italy</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6">
                                <div className="contact-address">
                                    <div className="address-item">
                                        <div className="address-icon">
                                            <i className="icon icon-basic-geolocalize-01"></i></div>
                                        <div className="address-text">
                                            <h3 className="contact-title">Singapore Office</h3>
                                            <p>1 Sophia road #05-23,  <br />Peace Centre,<br />Singapore 228149</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6">
                                <div className="contact-address">
                                    <div className="address-item">
                                        <div className="address-text">
                                            <h3 className="contact-title">India Office (HQ)</h3>
                                            <p>Second Floor, Bhadra Centre,  <br />
                                                Kunnumpuram Road<br />
                                                Ayurveda College,<br />
                                                Trivandrum-695001<br />
                                                Contact – +91 471 2477556
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6">
                                <div className="contact-address">
                                    <div className="address-item">
                                        <div className="address-text">
                                            <h3 className="contact-title">India Office (BO)</h3>
                                            <p>Smart Business Centre<br />
                                                Thejaswini, Technopark<br />
                                                Trivandrum</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6">
                                <div className="contact-address">
                                    <div className="address-item">
                                        <div className="address-text">
                                            <h3 className="contact-title">Canada Office</h3>
                                            <p>1160 Tapscott Rd.<br />
                                            Scarborough, ON – M1X 1E9</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 col-sm-12 col-lg-5">
                        <h1>Send your query</h1>
                        <div className="contact-form">

                            <form action="https://formspree.io/f/mzbkkznl"
                                  method="POST" id="contact-form" name="myForm" className="form" onSubmit={submitForm}>

                                <div className="form-group">
                                    <label className="form-label" id="nameLabel" htmlFor="name">Name</label>
                                    <input type="text" className="form-control" id="name" name="name" placeholder="Your name" />
                                    <div className="warning-box">
                                        {error && error.name && (
                                            <span className="error-label">
                                                {error.name}
                                            </span>
                                        )}
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label className="form-label" id="emailLabel" htmlFor="email">Email</label>
                                    <input type="email" className="form-control" id="email" name="email" placeholder="Your Email" />
                                    <div className="warning-box">
                                        {error && error.email && (
                                            <span className="error-label">
                                                {error.email}
                                            </span>
                                        )}
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label className="form-label" id="subjectLabel" htmlFor="subject">Subject</label>
                                    <input type="text" className="form-control" id="subject" name="subject" placeholder="Subject" />
                                    <div className="warning-box">
                                        {error && error.subject && (
                                            <span className="error-label">
                                                {error.subject}
                                            </span>
                                        )}
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label className="form-label" id="messageLabel" htmlFor="message">Message</label>
                                    <textarea rows="3" cols="60" name="message" className="form-control" id="message" placeholder="Your message"></textarea>
                                    <div className="warning-box">
                                        {error && error.message && (
                                            <span className="error-label">
                                                {error.message}
                                            </span>
                                        )}
                                    </div>
                                </div>

                                <div className="margin-top-25">
                                    <button type="submit" className="btn-1 learn-more-btn">Send Message</button>
                                </div>
                                {status === "SUCCESS" && (
                                    <p
                                        className="contact-feedback"
                                        data-aos="zoom-out"
                                        data-aos-easing="ease-in"
                                        data-aos-once="true"
                                    >
                                        Thanks For The Feedback!!
                                    </p>
                                )}
                                {status === "ERROR" && (
                                    <p
                                        className="contact-feedback subscribe-msg"
                                        data-aos-delay="50"
                                        data-aos="zoom-out"
                                        data-aos-easing="ease-in"
                                        data-aos-once="true"
                                    >
                                        Something Went Wrong. Please Try Again After
                                        Sometime!!
                                    </p>
                                )}

                            </form>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Contact
