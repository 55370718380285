/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */
import React from "react"
import { Link } from "gatsby"
import "../css/style.css"
import blog from "../images/blog-bg.png"

const Blog = () => {
  return (
    <section className="skew-bg blog-wrapper" id="blog">
      <div className="container-lg container-md">
        <div className="row">
          <div className="col-md-12 col-sm-12 animate__animated animate__fadeInLeft">
            <h1 className="main-title">
              Creative
              <br />
              <span className="h1-bold">Blog</span>
            </h1>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 col-sm-12  col-lg-5 animate__animated animate__fadeInLeft" >
            <div className="blog-title-content">
              <p>
              The directory for connecting ideas and business.
              </p>
              <Link to="/" className="btn-1 learn-more-btn">
                Read Blog
              </Link>
            </div>
          </div>
          <div className="col-md-12 col-sm-12 col-lg-7  blog-right-box animate__animated animate__fadeInRight">
            <div className="blog-bg animation-shake-vertical">
              <img src={blog} alt="Xminds" />
            </div>
            <Link className="blog-link" to="/">
              <div className="blog-box animation-shake-vertical">
                <h2>Check latest Blog</h2>
                <span className="blog-route">
                  <i className="fa fa-arrow-right" aria-hidden="true"></i>
                </span>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Blog
